import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Input} from '../../util/input';
import {LOCAL_STORAGE_PREFIX} from '../../util/storage';
import type {RootState} from '../util/store';

interface ConfigState {
  inputMap: {
    [key: number]: string;
  };
}

const initialState: ConfigState = {
  inputMap: {
    [Input.LEFT]: getMappingLocalStorage(Input.LEFT) || 'a',
    [Input.RIGHT]: getMappingLocalStorage(Input.RIGHT) || 'd',
    [Input.DOWN]: getMappingLocalStorage(Input.DOWN) || 's',
    [Input.UP]: getMappingLocalStorage(Input.UP) || 'w',
    [Input.A]: getMappingLocalStorage(Input.A) || 'k',
    [Input.B]: getMappingLocalStorage(Input.B) || 'j',
    [Input.START]: getMappingLocalStorage(Input.START) || 'i',
    [Input.SELECT]: getMappingLocalStorage(Input.SELECT) || 'u',
  },
};

export interface InputMapping {
  input: Input;
  mapping: string;
}

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateMapping: (state, action: PayloadAction<InputMapping>) => {
      state.inputMap[action.payload.input] = action.payload.mapping;
      updateMappingLocalStorage(action.payload.input, action.payload.mapping);
    },
  },
});

export const {updateMapping} = slice.actions;

export const selectInputMap = (state: RootState) => state.config.inputMap;

export const configReducer = slice.reducer;

function updateMappingLocalStorage(input: Input, mapping: string): void {
  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${input}`, mapping);
}

function getMappingLocalStorage(input: Input): string | null {
  return localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${input}`);
}
