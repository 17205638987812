import {useState} from 'react';
import {useImmer} from 'use-immer';
import {useGranularEffect} from 'granular-hooks';
import {io, Socket} from 'socket.io-client';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {selectHandle} from '../redux/reducers/multiplayer';
import {NavigateBack} from './NavigateBack';
import styles from './Lobby.module.scss';
import {Input} from '../util/input';
import {
  setNumPlayers,
  setLevel,
  setSpeed,
  setSeed,
  setHardDrop,
  setRemotePeerId,
} from '../redux/reducers/game_config';
import {Speed} from '../util/speed';
import {Screen, navigate, selectPressedInputs} from '../redux/reducers/app';
import {createPeer, getPeer} from '../util/peer';

interface Peer {
  id: string;
  handle: string;
  status_id: number;
}

type PeerMap = {[key: string]: Peer};
type SelectedIndexMap = {[key in UiElement]: number};

enum UiElement {
  PEERS,
  ACTIONS,
}

interface Action {
  label: string;
  onSelect: () => Promise<void>;
}

export function Lobby() {
  const dispatch = useAppDispatch();

  const handle = useAppSelector(selectHandle);
  const pressedInputs = useAppSelector(selectPressedInputs);

  const [peerMap, setPeerMap] = useImmer<PeerMap>({});
  const [sortedPeers, setSortedPeers] = useImmer<Peer[]>([]);
  const [peerId, setPeerId] = useState('');
  const [selectedIndexMap, setSelectedIndexMap] = useImmer<SelectedIndexMap>({
    [UiElement.PEERS]: 0,
    [UiElement.ACTIONS]: 0,
  });
  const [selectedUiElement, setSelectedUiElement] = useState(UiElement.PEERS);
  const [socket, setSocket] = useState<Socket | null>(null);

  const actions: Action[] = [
    {
      label: 'request match',
      onSelect: async () => {
        console.log('request match');
        const remotePeer = sortedPeers[selectedIndexMap[UiElement.PEERS]];
        if (!remotePeer) {
          return;
        }
        await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/peers/${peerId}/connect`,
          {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({remote_peer_id: remotePeer.id}),
          }
        );
      },
    },
    {
      label: 'set status',
      onSelect: async () => {
        console.log('set status');
      },
    },
  ];

  useGranularEffect(
    () => {
      createPeer();
    },
    [],
    []
  );

  useGranularEffect(
    () => {
      const socket = io(`${process.env.REACT_APP_WS_BASE_URL}`);
      socket.on('connect', async () => {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/peers`)
          .then((response) => response.json())
          .then((peers: Peer[]) => {
            setPeerMap((peerMap) => {
              peers.forEach((peer) => {
                peerMap[peer.id] = peer;
              });
            });
          });
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/peers`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({id: socket.id, handle}),
        });
        setPeerId(socket.id);
      });
      socket.on('deletePeer', ({id}: {id: string}) => {
        setPeerMap((peerMap) => {
          delete peerMap[id];
        });
        if (selectedIndexMap[UiElement.PEERS] >= Object.keys(peerMap).length) {
          setSelectedIndexMap((selectedIndexMap) => {
            selectedIndexMap[UiElement.PEERS]--;
          });
        }
      });
      setSocket(socket);
      return () => {
        if (socket.connected) {
          socket.disconnect();
        }
      };
    },
    [],
    [setPeerMap, setPeerId, setSelectedIndexMap]
  );

  // When the peer ID is set, reset any socket listeners that rely on it
  useGranularEffect(
    () => {
      if (!socket) {
        return;
      }
      socket.off('connectPeer');
      socket.on('connectPeer', ({ids}: {ids: string[]}) => {
        if (ids.includes(peerId)) {
          const remotePeerId = ids[1];
          const playerNumber = ids[0] === peerId ? 1 : 2;
          console.log('gotta start that match');
          dispatch(setNumPlayers(2));
          dispatch(setLevel(14));
          dispatch(setSpeed(Speed.HI));
          dispatch(setSeed('DEEF'));
          dispatch(setHardDrop(false));
          if (playerNumber === 1) {
            const peer = getPeer();
            socket.emit('connectPeerId', {peerId: peer.id, remotePeerId});
            dispatch(navigate(Screen.GAME));
          }
        }
      });
      socket.off('createPeer');
      socket.on('createPeer', (peer: Peer) => {
        if (peer.id === peerId) {
          return;
        }
        setPeerMap((peerMap) => {
          peerMap[peer.id] = peer;
        });
      });
      socket.off('setPeerStatus');
      socket.on('setPeerStatus', (peer: Peer) => {
        if (peer.id === peerId) {
          return;
        }
        setPeerMap((peerMap) => {
          peerMap[peer.id] = peer;
        });
      });
      socket.off('connectPeerId');
      socket.on('connectPeerId', (data) => {
        if (data.remotePeerId !== peerId) {
          return;
        }
        // This message was intended for me!
        dispatch(setRemotePeerId(data.peerId));
        dispatch(navigate(Screen.GAME));
      });
    },
    [peerId],
    [socket, setPeerMap]
  );

  useGranularEffect(
    () => {
      if (
        pressedInputs[Input.START] === false &&
        selectedUiElement === UiElement.ACTIONS
      ) {
        const selectedIndex = selectedIndexMap[selectedUiElement];
        if (selectedIndex !== undefined) {
          const action = actions[selectedIndex];
          if (action) {
            action.onSelect();
          }
        }
      } else if (pressedInputs[Input.UP] === false) {
        setSelectedIndexMap((selectedIndexMap) => {
          selectedIndexMap[selectedUiElement] = Math.max(
            0,
            selectedIndexMap[selectedUiElement] - 1
          );
        });
      } else if (pressedInputs[Input.DOWN] === false) {
        setSelectedIndexMap((selectedIndexMap) => {
          const max =
            selectedUiElement === UiElement.PEERS
              ? Math.max(0, Object.entries(peerMap).length - 1)
              : Math.max(0, actions.length - 1);
          selectedIndexMap[selectedUiElement] = Math.min(
            max,
            selectedIndexMap[selectedUiElement] + 1
          );
        });
      } else if (pressedInputs[Input.RIGHT] === false) {
        setSelectedUiElement(UiElement.ACTIONS);
      } else if (pressedInputs[Input.LEFT] === false) {
        setSelectedUiElement(UiElement.PEERS);
      }
    },
    [pressedInputs],
    [
      actions,
      selectedIndexMap,
      selectedUiElement,
      setSelectedIndexMap,
      setSelectedUiElement,
    ]
  );

  // When the peerMap changes, update the sorted array as it is computed from it
  useGranularEffect(
    () => {
      setSortedPeers(
        Object.values(peerMap).sort((a: Peer, b: Peer) =>
          a.handle.localeCompare(b.handle)
        )
      );
    },
    [peerMap],
    [setSortedPeers]
  );

  return (
    <div className={styles.El}>
      <NavigateBack />
      <div className={styles.Title}>lobby</div>
      <div className={styles.Lobby}>
        <div className={styles.Peers}>
          {sortedPeers.map((peer, index) => (
            <div
              key={peer.id}
              className={`${styles.Peer}${
                index === selectedIndexMap[UiElement.PEERS] &&
                selectedUiElement === UiElement.PEERS
                  ? ` ${styles.isUiElementSelected}`
                  : ''
              }${
                index === selectedIndexMap[UiElement.PEERS]
                  ? ` ${styles.isSelected}`
                  : ''
              }`}>
              {statusIndicator(peer.status_id)}
              {peer.handle}
            </div>
          ))}
        </div>
        <div className={styles.Actions}>
          {actions.map((action, index) => (
            <div
              key={action.label}
              className={`${styles.Action}${
                selectedUiElement === UiElement.ACTIONS &&
                index === selectedIndexMap[UiElement.ACTIONS]
                  ? ` ${styles.isSelected}`
                  : ''
              }`}
              onClick={action.onSelect}>
              {action.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function statusIndicator(statusId: number) {
  let className = '';
  switch (statusId) {
    case 1: // In Lobby
      className = styles.StatusInLobby;
      break;
    case 2: // Available
      className = styles.StatusAvailable;
      break;
    case 3: // Connected
      className = styles.StatusConnected;
      break;
  }
  return <div className={`${styles.StatusIndicator} ${className}`}></div>;
}
