import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../util/store';

interface TitleState {
  selectedIndex: number;
}

const initialState: TitleState = {
  selectedIndex: 0,
};

const slice = createSlice({
  name: 'title',
  initialState,
  reducers: {
    decrementSelectedIndex: (state) => {
      state.selectedIndex = Math.max(0, state.selectedIndex - 1);
    },
    incrementSelectedIndex: (state, action: PayloadAction<number>) => {
      state.selectedIndex = Math.min(
        action.payload - 1,
        state.selectedIndex + 1
      );
    },
  },
});

export const {decrementSelectedIndex, incrementSelectedIndex} = slice.actions;

export const selectSelectedIndex = (state: RootState) =>
  state.title.selectedIndex;

export const titleReducer = slice.reducer;
