import {useRef, useState} from 'react';
import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  setPressedRepeat,
  selectPressedInputs,
  selectGamepadIndex,
  setGamepadIndex,
} from '../redux/reducers/app';
import {updateMapping, selectInputMap} from '../redux/reducers/config';
import {NavigateBack} from './NavigateBack';
import {Input, getInputName} from '../util/input';
import {
  serializeGamepadMapping,
  GamepadPressType,
  GamepadPressDirection,
} from '../util/gamepad';
import styles from './Config.module.scss';

export function Config() {
  const dispatch = useAppDispatch();

  const pressedInputs = useAppSelector(selectPressedInputs);
  const inputMap = useAppSelector(selectInputMap);
  const gamepadIndex = useAppSelector(selectGamepadIndex);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [updatingInput, setUpdatingInput] = useState<Input | null>(null);

  useGranularEffect(
    () => {
      if (updatingInput !== null) {
        return;
      }

      if (pressedInputs[Input.START] === false) {
        setUpdatingInput(parseInt(Object.keys(inputMap)[selectedIndex], 10));
      } else if (pressedInputs[Input.UP] === false) {
        setSelectedIndex(Math.max(0, selectedIndex - 1));
      } else if (pressedInputs[Input.DOWN] === false) {
        setSelectedIndex(
          Math.min(Object.keys(inputMap).length - 1, selectedIndex + 1)
        );
      }
    },
    [pressedInputs, updatingInput],
    [inputMap, selectedIndex]
  );

  type KeydownHandler = (event: KeyboardEvent) => void;
  const keydownHandlerRef = useRef<KeydownHandler | null>(null);

  useGranularEffect(
    () => {
      if (updatingInput === null) {
        resetKeydownHandler();
        return;
      }
      keydownHandlerRef.current = (event: KeyboardEvent) => {
        if (event.repeat) {
          return;
        }
        updateMappingAndCloseDialog(event.key);
      };
      document.body.addEventListener('keydown', keydownHandlerRef.current);
      return () => {
        resetKeydownHandler();
      };
    },
    [updatingInput],
    [dispatch, updateMappingAndCloseDialog]
  );

  const previousGamepadRef = useRef<Gamepad | null>(null);
  const gamepadLoopRef = useRef<number | null>(null);

  useGranularEffect(
    () => {
      if (updatingInput === null || gamepadIndex === null) {
        resetGamepadLoop();
        return;
      }
      const gamepadLoop = () => {
        const gamepad = navigator.getGamepads()[gamepadIndex];
        if (!gamepad) {
          dispatch(setGamepadIndex(null));
          return;
        }
        for (let i = 0; i < gamepad.buttons.length; i++) {
          if (
            gamepad.buttons[i].pressed &&
            previousGamepadRef.current &&
            !previousGamepadRef.current.buttons[i].pressed
          ) {
            const gamepadMapping = serializeGamepadMapping({
              pressType: GamepadPressType.BUTTON,
              index: i,
              pressDirection: GamepadPressDirection.POSITIVE,
            });
            updateMappingAndCloseDialog(gamepadMapping);
          }
        }
        for (let i = 0; i < gamepad.axes.length; i++) {
          if (
            Math.abs(gamepad.axes[i]) > 0.5 &&
            previousGamepadRef.current &&
            Math.abs(previousGamepadRef.current.axes[i]) <= 0.5
          ) {
            const gamepadMapping = serializeGamepadMapping({
              pressType: GamepadPressType.AXIS,
              index: i,
              pressDirection:
                gamepad.axes[i] > 0
                  ? GamepadPressDirection.POSITIVE
                  : GamepadPressDirection.NEGATIVE,
            });
            updateMappingAndCloseDialog(gamepadMapping);
          }
        }
        previousGamepadRef.current = gamepad;
        gamepadLoopRef.current = requestAnimationFrame(gamepadLoop);
      };
      gamepadLoopRef.current = requestAnimationFrame(gamepadLoop);
      return () => {
        resetGamepadLoop();
      };
    },
    [updatingInput, gamepadIndex],
    [dispatch, updateMappingAndCloseDialog]
  );

  function resetGamepadLoop(): void {
    if (gamepadLoopRef.current !== null) {
      cancelAnimationFrame(gamepadLoopRef.current);
      gamepadLoopRef.current = null;
      previousGamepadRef.current = null;
    }
  }

  function resetKeydownHandler(): void {
    if (keydownHandlerRef.current !== null) {
      document.body.removeEventListener('keydown', keydownHandlerRef.current);
      keydownHandlerRef.current = null;
    }
  }

  function updateMappingAndCloseDialog(mapping: string): void {
    if (updatingInput === null) {
      return;
    }
    dispatch(
      updateMapping({
        input: updatingInput,
        mapping,
      })
    );
    Object.keys(inputMap)
      .map((inputString) => parseInt(inputString, 10))
      .filter((input) => mapping === inputMap[input])
      .concat([updatingInput])
      .forEach((input) => {
        dispatch(setPressedRepeat(input));
      });
    setUpdatingInput(null);
  }

  return (
    <div className={styles.El}>
      <NavigateBack enabled={updatingInput === null} />
      <div className={styles.InputMappings}>
        {Object.keys(inputMap).map((inputString, index) => {
          const input = parseInt(inputString, 10);
          const mapping = inputMap[input];
          return (
            <div
              key={input}
              className={
                styles.InputMapping +
                (index === selectedIndex ? ` ${styles.isSelected}` : '')
              }
              onClick={() => {
                setUpdatingInput(input);
              }}>
              <div className={styles.Input}>
                &lt;{getInputName(input)}&gt; &#8594;
              </div>
              <div className={styles.Mapping}>{formatMapping(mapping)}</div>
            </div>
          );
        })}
      </div>
      {updatingInput !== null && (
        <div className={styles.UpdatingDialogContainer}>
          <div className={styles.UpdatingDialog}>
            updating mapping for: &lt;{getInputName(updatingInput)}&gt;
            <br />
            waiting for input...
            <br />
            <button
              className={styles.CancelButton}
              onClick={(event) => {
                event.preventDefault();
                setUpdatingInput(null);
              }}>
              cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function formatMapping(mapping: string): string {
  return mapping === ' ' ? 'space' : mapping;
}
