import {RefObject, useEffect, useRef} from 'react';

import {
  NUM_CAPSULES,
  Phase,
  PhaseType,
  PlayerState,
  State,
  isPlayerActive,
} from './Game';
import {HEIGHT, WIDTH} from '../util/board';
import {Color} from '../util/color';
import {Capsule, Entities, EntityType} from '../util/entity';
import {Position} from '../util/position';
import {Seed} from '../util/seed';
import {SPEED_BASES, speedToString} from '../util/speed';
import styles from './GameRenderer.module.scss';

const GRID_SIZE = 4;
const PADDING_WIDTH = 1;
const BORDER_WIDTH = 1;

const CONFIG_COLOR: Map<Color, string> = new Map([
  [Color.COLOR_ONE, '#ff0'],
  [Color.COLOR_TWO, '#f00'],
  [Color.COLOR_THREE, '#00f'],
  [Color.COLOR_INVALID, '#000'],
]);

interface Props {
  numPlayers: number;
  connected: boolean;
  state: State;
  playerState: PlayerState;
}

export function GameRenderer({
  numPlayers,
  connected,
  state,
  playerState,
}: Props) {
  // Rendering code
  const canvasBottleRef = useRef<HTMLCanvasElement>(null);
  const canvasNextCapsuleRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvasBottle = canvasBottleRef.current;
    if (!canvasBottle) {
      return;
    }
    const contextBottle = canvasBottle.getContext('2d');
    if (!contextBottle) {
      return;
    }
    const canvasNextCapsule = canvasNextCapsuleRef.current;
    if (!canvasNextCapsule) {
      return;
    }
    const contextNextCapsule = canvasNextCapsule.getContext('2d');
    if (!contextNextCapsule) {
      return;
    }
    renderGame(
      contextBottle,
      contextNextCapsule,
      playerState.playerNumber === 1 ? state.player1State : state.player2State,
      playerState.playerNumber === 1 ? state.player2State : state.player1State,
      state.isPaused
    );
  });
  function renderGame(
    contextBottle: CanvasRenderingContext2D,
    contextNextCapsule: CanvasRenderingContext2D,
    playerState: PlayerState,
    otherPlayerState: PlayerState,
    isPaused: boolean
  ) {
    contextBottle.fillStyle = '#000';
    contextBottle.fillRect(
      0,
      0,
      contextBottle.canvas.width,
      contextBottle.canvas.height
    );
    contextNextCapsule.fillStyle = '#fff';
    contextNextCapsule.fillRect(
      0,
      0,
      contextNextCapsule.canvas.width,
      contextNextCapsule.canvas.height
    );
    contextNextCapsule.fillStyle = '#000';
    contextNextCapsule.fillRect(
      BORDER_WIDTH,
      BORDER_WIDTH,
      contextNextCapsule.canvas.width - BORDER_WIDTH * 2,
      contextNextCapsule.canvas.height - BORDER_WIDTH
    );
    const {position, rotation, entities, activeCapsuleIndex, phase} =
      playerState;
    const {phase: otherPlayerPhase} = otherPlayerState;
    if (connected || numPlayers === 1) {
      renderEntities(contextBottle, entities);
      if (isPlayerActive(phase)) {
        renderCapsule(
          contextBottle,
          position,
          rotation,
          state.capsules[activeCapsuleIndex],
          true /* isActive */
        );
      }
      renderCapsule(
        contextNextCapsule,
        {x: 0, y: 0},
        0,
        state.capsules[
          (activeCapsuleIndex + (isPlayerActive(phase) ? 1 : 0)) % NUM_CAPSULES
        ]
      );
      renderWinLose(contextBottle, phase, otherPlayerPhase);
    }
    contextBottle.fillStyle = '#fff';
    contextBottle.fillRect(0, 0, contextBottle.canvas.width, BORDER_WIDTH);
    contextBottle.fillRect(0, 0, BORDER_WIDTH, contextBottle.canvas.height);
    contextBottle.fillRect(
      0,
      contextBottle.canvas.height - 1,
      contextBottle.canvas.width,
      BORDER_WIDTH
    );
    contextBottle.fillRect(
      contextBottle.canvas.width - 1,
      0,
      BORDER_WIDTH,
      contextBottle.canvas.height
    );
    if (isPaused) {
      contextBottle.fillStyle = 'rgba(255, 255, 255, .2)';
      contextBottle.fillRect(
        0,
        0,
        contextBottle.canvas.width,
        contextBottle.canvas.height
      );
    }
  }
  function renderCapsule(
    context: CanvasRenderingContext2D,
    position: Position,
    rotation: number,
    capsule: Capsule,
    isActive: boolean = false
  ) {
    if (rotation % 2 === 0) {
      // Horizontal
      renderCapsuleLeft(
        context,
        rotation === 0 ? capsule.colorLeft : capsule.colorRight,
        position,
        isActive
      );
      renderCapsuleRight(
        context,
        rotation === 0 ? capsule.colorRight : capsule.colorLeft,
        {x: position.x + 1, y: position.y},
        isActive
      );
    } else {
      // Vertical
      renderCapsuleDown(
        context,
        rotation === 1 ? capsule.colorLeft : capsule.colorRight,
        position,
        isActive
      );
      renderCapsuleUp(
        context,
        rotation === 1 ? capsule.colorRight : capsule.colorLeft,
        {x: position.x, y: position.y - 1},
        isActive
      );
    }
  }
  function renderEntities(
    context: CanvasRenderingContext2D,
    entities: Entities
  ): void {
    entities.forEach((entity, index) => {
      if (entity == null) {
        return;
      }
      const {entityType, color} = entity;
      const position: Position = {
        x: index % WIDTH,
        y: Math.floor(index / WIDTH),
      };
      switch (entityType) {
        case EntityType.VIRUS:
          renderVirus(context, color, position);
          break;
        case EntityType.CAPSULE_LEFT:
          renderCapsuleLeft(context, color, position);
          break;
        case EntityType.CAPSULE_RIGHT:
          renderCapsuleRight(context, color, position);
          break;
        case EntityType.CAPSULE_DOWN:
          renderCapsuleDown(context, color, position);
          break;
        case EntityType.CAPSULE_UP:
          renderCapsuleUp(context, color, position);
          break;
        case EntityType.CAPSULE_DETACHED:
          renderCapsuleDetached(context, color, position);
          break;
        case EntityType.CLEARING:
          renderClearing(context, color, position);
          break;
      }
    });
  }
  function renderVirus(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position);
    context.fillRect(x, y, 1, 1);
    context.fillRect(x + 2, y, 1, 1);
    context.fillRect(x + 1, y + 1, 1, 1);
    context.fillRect(x, y + 2, 1, 1);
    context.fillRect(x + 2, y + 2, 1, 1);
  }
  function renderCapsuleLeft(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position,
    isActive: boolean = false
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position, isActive);
    context.fillRect(x, y + 1, 1, 1);
    context.fillRect(x + 1, y, 2, 3);
  }
  function renderCapsuleRight(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position,
    isActive: boolean = false
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position, isActive);
    context.fillRect(x, y, 2, 3);
    context.fillRect(x + 2, y + 1, 1, 1);
  }
  function renderCapsuleDown(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position,
    isActive: boolean = false
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position, isActive);
    context.fillRect(x, y, 3, 2);
    context.fillRect(x + 1, y + 2, 1, 1);
  }
  function renderCapsuleUp(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position,
    isActive: boolean = false
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position, isActive);
    context.fillRect(x + 1, y, 1, 1);
    context.fillRect(x, y + 1, 3, 2);
  }
  function renderCapsuleDetached(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position);
    context.fillRect(x, y + 1, 3, 1);
    context.fillRect(x + 1, y, 1, 3);
  }
  function renderClearing(
    context: CanvasRenderingContext2D,
    color: Color,
    position: Position
  ) {
    context.fillStyle = CONFIG_COLOR.get(color)!;
    const {x, y} = getStartPosition(position);
    context.fillRect(x + 1, y, 1, 1);
    context.fillRect(x, y + 1, 1, 1);
    context.fillRect(x + 2, y + 1, 1, 1);
    context.fillRect(x + 1, y + 2, 1, 1);
  }
  function renderWinLose(
    context: CanvasRenderingContext2D,
    phase: Phase,
    otherPlayerPhase: Phase
  ) {
    if (
      phase.phaseType === PhaseType.WIN ||
      (numPlayers > 1 && otherPlayerPhase.phaseType === PhaseType.LOSE)
    ) {
      renderWin(context);
    } else if (
      phase.phaseType === PhaseType.LOSE ||
      (numPlayers > 1 && otherPlayerPhase.phaseType === PhaseType.WIN)
    ) {
      renderLose(context);
    }
  }
  function renderWin(context: CanvasRenderingContext2D) {
    renderMessageBox(context);
    const startRow = Math.floor(HEIGHT / 2 - 1);
    const startY = startRow * GRID_SIZE + BORDER_WIDTH + PADDING_WIDTH;
    const startX = 10 + BORDER_WIDTH + PADDING_WIDTH;
    context.fillStyle = '#0f0';
    context.fillRect(startX, startY + 5, 3, 1);
    context.fillRect(startX + 1, startY + 6, 5, 1);
    context.fillRect(startX + 2, startY + 7, 3, 1);
    context.fillRect(startX + 4, startY + 5, 3, 1);
    context.fillRect(startX + 5, startY + 4, 3, 1);
    context.fillRect(startX + 6, startY + 3, 3, 1);
    context.fillRect(startX + 7, startY + 2, 3, 1);
    context.fillRect(startX + 8, startY + 1, 3, 1);
    context.fillRect(startX + 9, startY, 3, 1);
  }
  function renderLose(context: CanvasRenderingContext2D) {
    renderMessageBox(context);
    const startRow = Math.floor(HEIGHT / 2 - 1);
    const startY = startRow * GRID_SIZE + BORDER_WIDTH + PADDING_WIDTH;
    const startX = 10 + BORDER_WIDTH + PADDING_WIDTH;
    context.fillStyle = '#f00';
    context.fillRect(startX, startY, 3, 1);
    context.fillRect(startX + 1, startY + 1, 3, 1);
    context.fillRect(startX + 2, startY + 2, 3, 1);
    context.fillRect(startX + 3, startY + 3, 5, 2);
    context.fillRect(startX + 2, startY + 5, 3, 1);
    context.fillRect(startX + 1, startY + 6, 3, 1);
    context.fillRect(startX, startY + 7, 3, 1);
    context.fillRect(startX + 8, startY, 3, 1);
    context.fillRect(startX + 7, startY + 1, 3, 1);
    context.fillRect(startX + 6, startY + 2, 3, 1);
    context.fillRect(startX + 6, startY + 5, 3, 1);
    context.fillRect(startX + 7, startY + 6, 3, 1);
    context.fillRect(startX + 8, startY + 7, 3, 1);
  }
  function renderMessageBox(context: CanvasRenderingContext2D) {
    const startRow = Math.floor(HEIGHT / 2 - 2);
    const startX = 1 + BORDER_WIDTH + PADDING_WIDTH;
    const startY = startRow * GRID_SIZE + BORDER_WIDTH + PADDING_WIDTH;
    context.fillStyle = '#fff';
    context.fillRect(startX, startY, WIDTH * GRID_SIZE - 3, 4 * GRID_SIZE);
    context.fillStyle = '#000';
    context.fillRect(
      startX + 1,
      startY + 1,
      WIDTH * GRID_SIZE - 5,
      4 * GRID_SIZE - 2
    );
  }
  return renderPlayer(
    canvasBottleRef,
    canvasNextCapsuleRef,
    state,
    playerState,
    numPlayers
  );
}

function renderPlayer(
  canvasPlayerBottleRef: RefObject<HTMLCanvasElement>,
  canvasPlayerNextCapsuleRef: RefObject<HTMLCanvasElement>,
  state: State,
  playerState: PlayerState,
  numPlayers: number
): JSX.Element {
  return (
    <div className={styles.Player}>
      <canvas
        className={styles.NextCapsule}
        ref={canvasPlayerNextCapsuleRef}
        width={GRID_SIZE * 2 + PADDING_WIDTH + BORDER_WIDTH * 2}
        height={GRID_SIZE + PADDING_WIDTH + BORDER_WIDTH}
      />
      <canvas
        className={styles.Bottle}
        ref={canvasPlayerBottleRef}
        width={WIDTH * GRID_SIZE + PADDING_WIDTH + BORDER_WIDTH * 2}
        height={HEIGHT * GRID_SIZE + PADDING_WIDTH + BORDER_WIDTH * 2}
      />
      <div className={styles.Info}>
        Level: {state.level}
        <br />
        Seed: {seedToString(state.seed)}
        <br />
        Virus:{' '}
        {
          playerState.entities.filter(
            (entity) => entity?.entityType === EntityType.VIRUS
          ).length
        }
        <br />
        Speed: {speedToString(playerState.speed)}
        <br />
        Speed value:{' '}
        {(SPEED_BASES.get(playerState.speed) || 0) + playerState.speedCounter}
        <br />
        Frame: {state.frame}
        {numPlayers === 1 && (
          <>
            <br />
            Total frames: {state.previousFrameTotal + state.frame}
          </>
        )}
        <br />
        DAS: {playerState.das}
      </div>
    </div>
  );
}

function getStartPosition(
  position: Position,
  isActive: boolean = false
): Position {
  return {
    x: position.x * GRID_SIZE + BORDER_WIDTH + PADDING_WIDTH,
    y:
      position.y * GRID_SIZE +
      BORDER_WIDTH +
      PADDING_WIDTH +
      (isActive ? 1 : 0),
  };
}

function seedToString(seed: Seed): string {
  return (
    seed[0].toString(16).padStart(2, '0') +
    seed[1].toString(16).padStart(2, '0')
  ).toLocaleUpperCase();
}
