import {Color} from './color';

export interface Entity {
  entityType: EntityType;
  color: Color;
}

export enum EntityType {
  VIRUS,
  CAPSULE_LEFT,
  CAPSULE_RIGHT,
  CAPSULE_DOWN,
  CAPSULE_UP,
  CAPSULE_DETACHED,
  CLEARING,
}

export type Entities = Array<Entity | undefined>;

export interface Capsule {
  colorLeft: Color;
  colorRight: Color;
}
