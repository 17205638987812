import {configureStore} from '@reduxjs/toolkit';

import {appReducer} from '../reducers/app';
import {configReducer} from '../reducers/config';
import {gameConfigReducer} from '../reducers/game_config';
import {multiplayerReducer} from '../reducers/multiplayer';
import {titleReducer} from '../reducers/title';

export const store = configureStore({
  reducer: {
    app: appReducer,
    config: configReducer,
    gameConfig: gameConfigReducer,
    multiplayer: multiplayerReducer,
    title: titleReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
