import Peer from 'peerjs';

let peer: Peer | undefined;

export function createPeer(): void {
  if (peer) {
    return;
  }
  peer = new Peer('', {
    host: process.env.REACT_APP_PEERJS_HOST,
    port: parseInt(process.env.REACT_APP_PEERJS_PORT || '443', 10),
  });
}

export function getPeer(): Peer {
  if (!peer) {
    throw new Error('peer undefined');
  }
  return peer;
}
