import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  Screen,
  setPressed,
  setNotPressed,
  clearPressed,
  selectInputListeningEnabled,
  selectScreen,
  setGamepadIndex,
  selectGamepadIndex,
} from '../redux/reducers/app';
import {selectInputMap} from '../redux/reducers/config';
import {Title} from './Title';
import {Config} from './Config';
import {GameConfig} from './GameConfig';
import {LobbyConfig} from './LobbyConfig';
import {Lobby} from './Lobby';
import {Game} from './Game';
import {
  deserializeGamepadMapping,
  GamepadPressDirection,
  GamepadPressType,
} from '../util/gamepad';
import styles from './App.module.scss';

export function App() {
  const dispatch = useAppDispatch();

  const screen = useAppSelector(selectScreen);
  const inputMap = useAppSelector(selectInputMap);
  const gamepadIndex = useAppSelector(selectGamepadIndex);
  const inputListeningEnabled = useAppSelector(selectInputListeningEnabled);

  useGranularEffect(
    () => {
      if (!inputListeningEnabled) {
        dispatch(clearPressed());
        return;
      }
      const keydownHandler = (event: KeyboardEvent) => {
        event.preventDefault();
        for (const [inputString, mapping] of Object.entries(inputMap)) {
          if (mapping === event.key) {
            const input = parseInt(inputString, 10);
            dispatch(setPressed(input));
          }
        }
      };
      const keyupHandler = (event: KeyboardEvent) => {
        event.preventDefault();
        for (const [inputString, mapping] of Object.entries(inputMap)) {
          if (mapping === event.key) {
            const input = parseInt(inputString, 10);
            dispatch(setNotPressed(input));
          }
        }
      };
      const gamepadconnectedHandler = (event: GamepadEvent) => {
        dispatch(setGamepadIndex(event.gamepad.index));
      };
      const blurHandler = () => {
        dispatch(clearPressed());
      };
      document.body.addEventListener('keydown', keydownHandler);
      document.body.addEventListener('keyup', keyupHandler);
      window.addEventListener('gamepadconnected', gamepadconnectedHandler);
      window.addEventListener('blur', blurHandler);
      return () => {
        document.body.removeEventListener('keydown', keydownHandler);
        document.body.removeEventListener('keyup', keyupHandler);
        window.removeEventListener('gamepadconnected', gamepadconnectedHandler);
        window.removeEventListener('blur', blurHandler);
      };
    },
    [inputMap, inputListeningEnabled],
    [dispatch]
  );

  useGranularEffect(
    () => {
      if (gamepadIndex === null) {
        return;
      }
      let requestAnimationFrameId: number | null = null;
      const gamepadLoop = () => {
        const gamepad = navigator.getGamepads()[gamepadIndex];
        if (!gamepad) {
          dispatch(setGamepadIndex(null));
          return;
        }
        for (const [inputString, mapping] of Object.entries(inputMap)) {
          const gamepadMapping = deserializeGamepadMapping(mapping);
          if (gamepadMapping === null) {
            continue;
          }
          let isPressed = false;
          if (gamepadMapping.pressType === GamepadPressType.AXIS) {
            isPressed =
              (gamepad.axes[gamepadMapping.index] ?? 0) *
                (gamepadMapping.pressDirection ===
                GamepadPressDirection.POSITIVE
                  ? 1
                  : -1) >
              0.5;
          } else if (gamepadMapping.pressType === GamepadPressType.BUTTON) {
            isPressed = !!gamepad.buttons[gamepadMapping.index]?.pressed;
          }
          const input = parseInt(inputString, 10);
          if (isPressed) {
            dispatch(setPressed(input));
          } else {
            dispatch(setNotPressed(input));
          }
        }
        requestAnimationFrameId = requestAnimationFrame(gamepadLoop);
      };
      requestAnimationFrameId = requestAnimationFrame(gamepadLoop);
      return () => {
        if (requestAnimationFrameId) {
          cancelAnimationFrame(requestAnimationFrameId);
        }
      };
    },
    [gamepadIndex, inputMap],
    [dispatch]
  );

  return <div className={styles.El}>{renderScreen(screen)}</div>;
}

function renderScreen(screen: Screen) {
  switch (screen) {
    case Screen.TITLE:
      return <Title />;
    case Screen.CONFIG:
      return <Config />;
    case Screen.GAME_CONFIG:
      return <GameConfig />;
    case Screen.LOBBY_CONFIG:
      return <LobbyConfig />;
    case Screen.LOBBY:
      return <Lobby />;
    case Screen.GAME:
      return <Game />;
  }
}
