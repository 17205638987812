import {useEffect, useRef, useState} from 'react';
import {useGranularEffect} from 'granular-hooks';
import Peer from 'peerjs';
import {
  Telegraph,
  SaveResult,
  SyncInputResultValue,
  TelegraphEvent,
  PlayerType,
} from '@tboyt/telegraph';

import {Color, VIRUS_COLOR_TABLE, VIRUS_COLOR_BIT_MASKS} from '../util/color';
import {Capsule, Entities, Entity, EntityType} from '../util/entity';
import {Position} from '../util/position';
import {Input, getInputMap} from '../util/input';
import {Speed, getFallFrames} from '../util/speed';
import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  navigateBack,
  selectPressedInputs,
  setPressedRepeat,
} from '../redux/reducers/app';
import {
  selectHardDrop,
  selectLevel,
  selectNumPlayers,
  selectSeed,
  selectSpeed,
  selectRemotePeerId,
} from '../redux/reducers/game_config';
import {
  HEIGHT,
  WIDTH,
  getClearedIndices,
  hasCollision,
  isWin,
  updatePositionState,
  getEntityIndex,
} from '../util/board';
import {Seed, getRandomSeed, isValidSeed, stringToSeed} from '../util/seed';
import {getPeer} from '../util/peer';
import {GameRenderer} from './GameRenderer';
import styles from './Game.module.scss';

const FPS = 60;
const FRAME_STEP = 1000 / FPS;
const NUM_POSITIONS = WIDTH * HEIGHT;
export const NUM_CAPSULES = 128;

const CONFIG_ENTRY_DELAY = 40;
const CONFIG_CLEARING = 15;
const CONFIG_DROP = 15;

const CONFIG_CLEAR_NUMBER = 4;
const CONFIG_MAX_GARBAGE = 4;

const queryParams = new URLSearchParams(document.location.search);
const CONFIG_FRAME_DELAY = queryParams.has('frameDelay')
  ? parseInt(queryParams.get('frameDelay')!, 10)
  : 2;

export interface State {
  frame: number;
  previousFrameTotal: number;
  capsules: Capsule[];
  level: number;
  seed: Seed;
  nextSeed: Seed;
  isPaused: boolean;
  player1State: PlayerState;
  player2State: PlayerState;
}

export interface PlayerState {
  inputs: Set<Input>;
  position: Position;
  rotation: number;
  entities: Entities;
  das: number;
  fallCounter: number;
  activeCapsuleIndex: number;
  numCapsules: number;
  phase: Phase;
  pendingAttackColors: Color[];
  pendingGarbageColors: Color[];
  pendingGarbageFrame: number;
  playerNumber: number;
  speed: Speed;
  speedCounter: number;
}

export enum PhaseType {
  PLAYER_ACTIVE,
  CHECK_FOR_CLEARS,
  CLEARING,
  DROP_UNATTACHED,
  ENTRY_DELAY,
  WIN,
  LOSE,
}

export interface Phase {
  phaseType: PhaseType;
  data: number;
}

const INITIAL_POSITION_X = 3;
const INITIAL_POSITION_Y = 0;

let handle = 0;

export function Game() {
  const dispatch = useAppDispatch();

  const pressedInputs = useAppSelector(selectPressedInputs);
  const pressedInputsRef = useRef(pressedInputs);
  useEffect(() => {
    pressedInputsRef.current = pressedInputs;
  }, [pressedInputs]);

  const level = useAppSelector(selectLevel);
  const speed = useAppSelector(selectSpeed);
  const seed = useAppSelector(selectSeed);
  const hardDrop = useAppSelector(selectHardDrop);
  const numPlayers = useAppSelector(selectNumPlayers);
  const remoteId = useAppSelector(selectRemotePeerId);

  const initialSeed = isValidSeed(seed) ? stringToSeed(seed) : getRandomSeed();

  const [state, setState] = useState<State>(
    resetState(initialSeed, level, speed)
  );
  const stateRef = useRef(state);
  const telegraphRef = useRef<Telegraph<string> | null>(null);
  useEffect(() => {
    // On every rerender, store the current state
    stateRef.current = state;
  });
  function runFixedUpdate(): void {
    const localInputs = Object.keys(pressedInputsRef.current).map(
      (inputString) => parseInt(inputString, 10)
    );
    if (numPlayers > 1) {
      if (!telegraphRef.current) {
        return;
      }
      const addLocalInputResult = telegraphRef.current.addLocalInput(
        handle,
        localInputs
      );
      if (!addLocalInputResult || addLocalInputResult.code === 'ok') {
        const inputResult = telegraphRef.current.syncInput();
        if (inputResult.code === 'ok') {
          advanceFrame(inputResult.value!);
        } else {
          console.log('[Game] non-ok result for syncInput:', inputResult.code);
        }
      }
    } else {
      updateState(new Set(localInputs), new Set());
    }
  }
  function advanceFrame({inputs}: SyncInputResultValue): void {
    updateState(new Set(inputs[0]), new Set(inputs[1]));
    telegraphRef.current?.advanceFrame();
  }
  function runRollbackUpdate(): void {
    const inputResult = telegraphRef.current?.syncInput();
    if (!inputResult?.value) {
      return;
    }
    advanceFrame(inputResult.value);
  }
  function updateState(
    player1Inputs: Set<number>,
    player2Inputs: Set<number>
  ): void {
    const player1PhaseType = stateRef.current.player1State.phase.phaseType;
    const player2PhaseType = stateRef.current.player2State.phase.phaseType;
    if (
      player1PhaseType === PhaseType.WIN ||
      player1PhaseType === PhaseType.LOSE ||
      (numPlayers > 1 &&
        (player2PhaseType === PhaseType.WIN ||
          player2PhaseType === PhaseType.LOSE))
    ) {
      if (numPlayers === 1 && player1Inputs.has(Input.START)) {
        if (player1PhaseType === PhaseType.WIN) {
          stateRef.current = resetState(
            stateRef.current.nextSeed,
            stateRef.current.level + 1,
            speed,
            stateRef.current.previousFrameTotal + stateRef.current.frame
          );
        } else {
          cancelLoop();
          dispatch(setPressedRepeat(Input.START));
          dispatch(navigateBack());
        }
      } else if (numPlayers > 1 && player1Inputs.has(Input.START)) {
        stateRef.current = resetState(
          stateRef.current.nextSeed,
          stateRef.current.level,
          speed
        );
      }
      return;
    }
    if (
      (stateRef.current.frame > 0 &&
        player1Inputs.has(Input.START) &&
        !stateRef.current.player1State.inputs.has(Input.START)) ||
      (player2Inputs.has(Input.START) &&
        !stateRef.current.player2State.inputs.has(Input.START))
    ) {
      stateRef.current.isPaused = !stateRef.current.isPaused;
    }
    if (stateRef.current.isPaused) {
      stateRef.current.player1State.inputs = player1Inputs;
      stateRef.current.player2State.inputs = player2Inputs;
      return;
    }
    stateRef.current.player1State = updatePlayerState(
      stateRef.current.player1State,
      player1Inputs
    );
    if (numPlayers > 1) {
      stateRef.current.player2State = updatePlayerState(
        stateRef.current.player2State,
        player2Inputs
      );
    }
    stateRef.current = {
      frame: stateRef.current.frame + 1,
      previousFrameTotal: stateRef.current.previousFrameTotal,
      capsules: stateRef.current.capsules,
      level: stateRef.current.level,
      seed: stateRef.current.seed,
      nextSeed: stateRef.current.nextSeed,
      isPaused: stateRef.current.isPaused,
      player1State: stateRef.current.player1State,
      player2State: stateRef.current.player2State,
    };
  }
  function updatePlayerState(
    previousState: PlayerState,
    inputs: Set<number>
  ): PlayerState {
    const updateFns = [
      updateStateInputs,
      updateStatePositionDasFall,
      updateStateEntities,
    ];
    const state: PlayerState = {
      inputs: new Set(previousState.inputs),
      position: Object.assign({}, previousState.position),
      rotation: previousState.rotation,
      entities: previousState.entities.slice(),
      das: previousState.das,
      fallCounter: previousState.fallCounter,
      activeCapsuleIndex: previousState.activeCapsuleIndex,
      numCapsules: previousState.numCapsules,
      phase: Object.assign({}, previousState.phase),
      pendingAttackColors: previousState.pendingAttackColors.slice(),
      pendingGarbageColors: previousState.pendingGarbageColors.slice(),
      pendingGarbageFrame: previousState.pendingGarbageFrame,
      playerNumber: previousState.playerNumber,
      speed: previousState.speed,
      speedCounter: previousState.speedCounter,
    };
    for (const updateFn of updateFns) {
      updateFn(state, previousState, inputs);
    }
    return state;
  }
  function updateStateInputs(
    state: PlayerState,
    previousState: PlayerState,
    inputs: Set<Input>
  ): void {
    state.inputs = new Set(inputs);
  }
  function updateStatePositionDasFall(
    state: PlayerState,
    previousState: PlayerState,
    inputs: Set<Input>
  ): void {
    if (!isPlayerActive(previousState.phase)) {
      // Only update position if under the player's control
      return;
    }
    let position: Position = Object.assign({}, previousState.position);
    let das = previousState.das;
    let fallCounter = previousState.fallCounter;
    let activeCapsuleIndex = previousState.activeCapsuleIndex;
    let rotation = previousState.rotation;
    const entities = previousState.entities.slice();
    if (hasCollision(entities, position, rotation)) {
      // If there is a collision before we update position/rotation, then it
      // must be the next active capsule at the top of the bottle. Render it and
      // lose the game
      state.entities[getEntityIndex(position)] = {
        color: stateRef.current.capsules[activeCapsuleIndex].colorLeft,
        entityType: EntityType.CAPSULE_LEFT,
      };
      state.entities[getEntityIndex(position, rotation)] = {
        color: stateRef.current.capsules[activeCapsuleIndex].colorRight,
        entityType: EntityType.CAPSULE_RIGHT,
      };
      state.phase.phaseType = PhaseType.LOSE;
      return;
    }
    const updatedPositionState = updatePositionState(
      getInputMap(inputs, previousState.inputs),
      state.entities,
      {
        position,
        das,
        fallCounter,
        rotation,
        isLocked: false,
      },
      getFallFrames(state.speed, state.speedCounter),
      hardDrop
    );
    position = updatedPositionState.position;
    das = updatedPositionState.das;
    fallCounter = updatedPositionState.fallCounter;
    rotation = updatedPositionState.rotation;
    if (updatedPositionState.isLocked) {
      const activeCapsule = stateRef.current.capsules[activeCapsuleIndex];
      entities[getEntityIndex(position)] = {
        entityType:
          rotation % 2 === 0
            ? EntityType.CAPSULE_LEFT
            : EntityType.CAPSULE_DOWN,
        color:
          rotation < 2 ? activeCapsule.colorLeft : activeCapsule.colorRight,
      };
      const rightUpPosition = getEntityIndex(position, rotation);
      if (
        rightUpPosition >= 0 &&
        (rotation % 2 === 1 || previousState.position.x < WIDTH - 1)
      ) {
        entities[rightUpPosition] = {
          entityType:
            rotation % 2 === 0
              ? EntityType.CAPSULE_RIGHT
              : EntityType.CAPSULE_UP,
          color:
            rotation < 2 ? activeCapsule.colorRight : activeCapsule.colorLeft,
        };
      }
      activeCapsuleIndex = (activeCapsuleIndex + 1) % NUM_CAPSULES;
      state.numCapsules++;
      if (state.numCapsules % 10 === 0) {
        state.speedCounter++;
      }
      position.y = 0;
      position.x = 3;
      rotation = 0;
      state.phase = {
        phaseType: PhaseType.CHECK_FOR_CLEARS,
        data: 0,
      };
    }
    state.position = position;
    state.rotation = rotation;
    state.das = das;
    state.fallCounter = fallCounter;
    state.activeCapsuleIndex = activeCapsuleIndex;
    state.entities = entities;
  }
  function updateStateEntities(
    state: PlayerState,
    previousState: PlayerState,
    inputs: Set<Input>
  ): void {
    if (isPlayerActive(previousState.phase)) {
      // Only update entities (check clears, update entry delay) if not active
      return;
    }
    if (previousState.phase.phaseType === PhaseType.ENTRY_DELAY) {
      if (previousState.phase.data === 0) {
        state.phase.phaseType = PhaseType.PLAYER_ACTIVE;
      } else {
        state.phase.data--;
      }
    } else if (previousState.phase.phaseType === PhaseType.CHECK_FOR_CLEARS) {
      if (previousState.phase.data > 0) {
        state.phase.data--;
        return;
      }
      let hasClears = false;
      for (let row = 0; row < HEIGHT; row++) {
        const rowEntities = state.entities.slice(
          row * WIDTH,
          (row + 1) * WIDTH
        );
        const clearedIndices = getClearedIndices(
          rowEntities,
          CONFIG_CLEAR_NUMBER
        );
        if (row === 0) {
          for (let i = 0; i < rowEntities.length; i++) {
            const entity = rowEntities[i];
            if (entity?.entityType === EntityType.CAPSULE_DOWN) {
              setCapsuleDetached(state, i);
            }
          }
        }
        if (clearedIndices.length > 0) {
          const clearedEntityIndices = clearedIndices.map(
            (clearedIndex) => row * WIDTH + clearedIndex
          );
          const clearedColors = new Set(
            clearedEntityIndices.map((index) => state.entities[index]!.color)
          );
          state.pendingAttackColors.push(...clearedColors);
          updateClearedEntities(state, clearedEntityIndices);
          hasClears = true;
        }
      }
      for (let column = 0; column < WIDTH; column++) {
        const columnEntities = [];
        for (
          let position = column;
          position < NUM_POSITIONS;
          position += WIDTH
        ) {
          columnEntities.push(state.entities[position]);
        }
        const clearedIndices = getClearedIndices(
          columnEntities,
          CONFIG_CLEAR_NUMBER
        );
        if (clearedIndices.length > 0) {
          const clearedEntityIndices = clearedIndices.map(
            (clearedIndex) => column + clearedIndex * WIDTH
          );
          const clearedColors = new Set(
            clearedEntityIndices.map((index) => state.entities[index]!.color)
          );
          state.pendingAttackColors.push(...clearedColors);
          updateClearedEntities(state, clearedEntityIndices);
          hasClears = true;
        }
      }
      if (hasClears && isWin(state.entities)) {
        state.phase.phaseType = PhaseType.WIN;
        return;
      }
      state.phase = {
        phaseType: PhaseType.CLEARING,
        data: hasClears ? CONFIG_CLEARING : 0,
      };
    } else if (previousState.phase.phaseType === PhaseType.CLEARING) {
      if (state.phase.data > 0) {
        state.phase.data--;
        return;
      }
      const entities: Entities = [];
      for (const [index, entity] of state.entities.entries()) {
        if (!entity || entity.entityType === EntityType.CLEARING) {
          continue;
        }
        entities[index] = entity;
      }
      state.entities = entities;
      if (!isLocked(entities)) {
        state.phase = {
          phaseType: PhaseType.DROP_UNATTACHED,
          data: 0,
        };
      } else {
        nextCapsule(state);
      }
    } else if (previousState.phase.phaseType === PhaseType.DROP_UNATTACHED) {
      if (state.phase.data > 0) {
        state.phase.data--;
        return;
      }
      const entities: Entities = state.entities;
      for (let index = NUM_POSITIONS - 1; index >= 0; index--) {
        const entity = entities[index];
        const downPosition = index + WIDTH;
        if (!entity || downPosition >= NUM_POSITIONS) {
          continue;
        }
        switch (entity.entityType) {
          case EntityType.CAPSULE_DETACHED:
            if (entities[downPosition] === undefined) {
              entities[downPosition] = {
                entityType: entity.entityType,
                color: entity.color,
              };
              delete entities[index];
            }
            break;
          case EntityType.CAPSULE_LEFT:
            if (
              entities[downPosition] === undefined &&
              entities[downPosition + 1] === undefined
            ) {
              entities[downPosition] = {
                entityType: entity.entityType,
                color: entity.color,
              };
              entities[downPosition + 1] = {
                entityType: entities[index + 1]!.entityType,
                color: entities[index + 1]!.color,
              };
              delete entities[index];
              delete entities[index + 1];
            }
            break;
          case EntityType.CAPSULE_DOWN:
            if (entities[downPosition] === undefined) {
              entities[downPosition] = {
                entityType: entity.entityType,
                color: entity.color,
              };
              entities[index] = {
                entityType: entities[index - WIDTH]!.entityType,
                color: entities[index - WIDTH]!.color,
              };
              delete entities[index - WIDTH];
            }
            break;
        }
      }
      state.entities = entities;
      if (!isLocked(entities)) {
        state.phase.data = CONFIG_DROP;
      } else {
        state.phase = {
          phaseType: PhaseType.CHECK_FOR_CLEARS,
          data: 0,
        };
      }
    }
  }
  function nextCapsule(state: PlayerState) {
    if (state.pendingAttackColors.length > 1) {
      const otherPlayerState =
        state.playerNumber === 1
          ? stateRef.current.player2State
          : stateRef.current.player1State;
      otherPlayerState.pendingGarbageColors.push(...state.pendingAttackColors);
      otherPlayerState.pendingGarbageFrame = stateRef.current.frame;
    }
    if (state.pendingGarbageColors.length > 1) {
      const numGarbageCapsules = Math.min(
        CONFIG_MAX_GARBAGE,
        state.pendingGarbageColors.length
      );
      const startPosition =
        state.pendingGarbageFrame % (WIDTH / numGarbageCapsules > 2 ? 4 : 2);
      const step = Math.floor(WIDTH / numGarbageCapsules);
      for (let i = 0; i < numGarbageCapsules; i++) {
        state.entities[startPosition + i * step] = {
          entityType: EntityType.CAPSULE_DETACHED,
          color: state.pendingGarbageColors[i],
        };
      }
      state.phase = {
        phaseType: PhaseType.CHECK_FOR_CLEARS,
        data: CONFIG_DROP,
      };
    } else {
      state.phase = {
        phaseType: PhaseType.ENTRY_DELAY,
        data: CONFIG_ENTRY_DELAY,
      };
    }
    state.pendingAttackColors = [];
    state.pendingGarbageColors = [];
    state.pendingGarbageFrame = -1;
  }
  function updateClearedEntities(
    state: PlayerState,
    clearedEntityIndices: number[]
  ): void {
    for (const clearedEntityIndex of clearedEntityIndices) {
      const entity = state.entities[clearedEntityIndex];
      if (entity === undefined) {
        continue;
      }
      switch (entity.entityType) {
        case EntityType.CAPSULE_LEFT:
          setCapsuleDetached(state, clearedEntityIndex + 1);
          break;
        case EntityType.CAPSULE_RIGHT:
          setCapsuleDetached(state, clearedEntityIndex - 1);
          break;
        case EntityType.CAPSULE_UP:
          setCapsuleDetached(state, clearedEntityIndex + WIDTH);
          break;
        case EntityType.CAPSULE_DOWN:
          setCapsuleDetached(state, clearedEntityIndex - WIDTH);
          break;
      }
      state.entities[clearedEntityIndex]!.entityType = EntityType.CLEARING;
    }
  }
  const lastTime = useRef(performance.now());
  const lag = useRef(0);
  const nextLoop = useRef(0);
  const [connected, setConnected] = useState(false);
  useGranularEffect(
    () => {
      let cancel: () => void;
      if (numPlayers > 1) {
        const peer = getPeer();
        if (remoteId) {
          const conn = peer.connect(remoteId);
          conn.on('open', () => {
            setConnected(true);
            cancel = run(peer, conn.peer);
          });
        } else {
          peer.on('connection', (conn) => {
            conn.on('open', () => {
              setConnected(true);
              cancel = run(peer, conn.peer);
            });
          });
        }
      } else {
        cancel = run();
      }
      return () => {
        if (cancel) {
          cancel();
        }
      };
    },
    [numPlayers],
    [run]
  );
  function run(peer?: Peer, remotePeerId?: string): () => void {
    if (peer) {
      telegraphRef.current = new Telegraph({
        peer,
        disconnectNotifyStart: 1000,
        disconnectTimeout: 3000,
        numPlayers: 2,

        callbacks: {
          onAdvanceFrame: (): void => runRollbackUpdate(),
          onLoadState: (snapshot): void => {
            const stateObject = JSON.parse(snapshot);
            stateRef.current = {
              frame: stateObject.frame,
              previousFrameTotal: stateObject.previousFrameTotal,
              capsules: stateObject.capsules,
              level: stateObject.level,
              seed: stateObject.seed,
              nextSeed: stateObject.nextSeed,
              isPaused: stateObject.isPaused,
              player1State: {
                inputs: new Set(stateObject.player1State.inputs),
                position: stateObject.player1State.position,
                rotation: stateObject.player1State.rotation,
                entities: stateObject.player1State.entities.map(
                  (entity: Entity | null | undefined) =>
                    entity === null ? undefined : entity
                ),
                das: stateObject.player1State.das,
                fallCounter: stateObject.player1State.fallCounter,
                activeCapsuleIndex: stateObject.player1State.activeCapsuleIndex,
                numCapsules: stateObject.player1State.numCapsules,
                phase: stateObject.player1State.phase,
                pendingAttackColors:
                  stateObject.player1State.pendingAttackColors,
                pendingGarbageColors:
                  stateObject.player1State.pendingGarbageColors,
                pendingGarbageFrame:
                  stateObject.player1State.pendingGarbageFrame,
                playerNumber: stateObject.player1State.playerNumber,
                speed: stateObject.player1State.speed,
                speedCounter: stateObject.player1State.speedCounter,
              },
              player2State: {
                inputs: new Set(stateObject.player2State.inputs),
                position: stateObject.player2State.position,
                rotation: stateObject.player2State.rotation,
                entities: stateObject.player2State.entities.map(
                  (entity: Entity | null | undefined) =>
                    entity === null ? undefined : entity
                ),
                das: stateObject.player2State.das,
                fallCounter: stateObject.player2State.fallCounter,
                activeCapsuleIndex: stateObject.player2State.activeCapsuleIndex,
                numCapsules: stateObject.player1State.numCapsules,
                phase: stateObject.player2State.phase,
                pendingAttackColors:
                  stateObject.player2State.pendingAttackColors,
                pendingGarbageColors:
                  stateObject.player2State.pendingGarbageColors,
                pendingGarbageFrame:
                  stateObject.player2State.pendingGarbageFrame,
                playerNumber: stateObject.player2State.playerNumber,
                speed: stateObject.player2State.speed,
                speedCounter: stateObject.player2State.speedCounter,
              },
            };
          },
          onSaveState: (): SaveResult<string> => {
            return {
              state: JSON.stringify(stateRef.current, (_, value) => {
                if (value instanceof Set) {
                  return [...value];
                } else if (value instanceof Map) {
                  return [...value];
                } else {
                  return value;
                }
              }),
              checksum: null,
            };
          },
          onEvent: (evt: TelegraphEvent): void => {
            console.log(evt);
          },
        },
      });

      handle = telegraphRef.current.addPlayer({
        playerNumber: remoteId ? 2 : 1,
        type: PlayerType.local,
      }).value!;

      telegraphRef.current.setFrameDelay(handle, CONFIG_FRAME_DELAY);

      telegraphRef.current.addPlayer({
        playerNumber: remoteId ? 1 : 2,
        type: PlayerType.remote,
        remote: {
          peerId: remotePeerId!,
        },
      });
    }
    nextLoop.current = requestAnimationFrame(loop);
    return cancelLoop;
  }
  function cancelLoop(): void {
    cancelAnimationFrame(nextLoop.current);
  }
  function loop(time: DOMHighResTimeStamp): void {
    const delta = time - lastTime.current;
    lag.current += delta;
    let didUpdate = false;
    while (lag.current >= FRAME_STEP) {
      runFixedUpdate();
      lag.current -= FRAME_STEP;
      didUpdate = true;
    }
    if (didUpdate) {
      // Cap renders to 60 FPS
      setState(stateRef.current);
    }
    lastTime.current = time;
    nextLoop.current = requestAnimationFrame(loop);
  }
  return (
    <div className={styles.Game}>
      <div className={styles.Players}>
        <GameRenderer
          connected={connected}
          numPlayers={numPlayers}
          state={stateRef.current}
          playerState={stateRef.current.player1State}
        />
        {numPlayers > 1 ? (
          <GameRenderer
            connected={connected}
            numPlayers={numPlayers}
            state={stateRef.current}
            playerState={stateRef.current.player2State}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function generateViruses(
  seed: Seed,
  level: number = 20
): {seed: Seed; viruses: Color[]} {
  const viruses: Color[] = [];
  const cappedLevel = Math.min(20, level);
  let virusesRemaining = (cappedLevel + 1) * 4;
  const maxRow = getMaxRow(cappedLevel);
  outerLoop: while (virusesRemaining > 0) {
    let row;
    do {
      seed = rotateBytes(seed);
    } while ((row = seed[0] % HEIGHT) > maxRow);
    const y = HEIGHT - 1 - row;
    const x = seed[1] % WIDTH;
    let position = y * WIDTH + x;
    let color: Color = virusesRemaining % 4;
    if (color === Color.COLOR_INVALID) {
      seed = rotateBytes(seed);
      color = VIRUS_COLOR_TABLE[seed[1] % 16];
    }
    adjustment: while (true) {
      while (true) {
        if (viruses[position] === undefined) {
          break;
        }
        if (++position >= WIDTH * HEIGHT) {
          continue outerLoop;
        }
      }
      let surroundingViruses = 0;
      const upVirus = viruses[position - 16];
      if (upVirus !== undefined) {
        surroundingViruses |= VIRUS_COLOR_BIT_MASKS[upVirus];
      }
      const downVirus = viruses[position + 16];
      if (downVirus !== undefined) {
        surroundingViruses |= VIRUS_COLOR_BIT_MASKS[downVirus];
      }
      if (position % WIDTH >= 2) {
        const leftVirus = viruses[position - 2];
        if (leftVirus !== undefined) {
          surroundingViruses |= VIRUS_COLOR_BIT_MASKS[leftVirus];
        }
      }
      if (position % WIDTH < 6) {
        const rightVirus = viruses[position + 2];
        if (rightVirus !== undefined) {
          surroundingViruses |= VIRUS_COLOR_BIT_MASKS[rightVirus];
        }
      }
      while (true) {
        if (surroundingViruses === 7) {
          position++;
          continue adjustment;
        }
        if ((surroundingViruses & VIRUS_COLOR_BIT_MASKS[color]) === 0) {
          break;
        }
        if (color === Color.COLOR_ONE) {
          color = Color.COLOR_THREE;
        } else if (color === Color.COLOR_TWO) {
          color = Color.COLOR_ONE;
        } else if (color === Color.COLOR_THREE) {
          color = Color.COLOR_TWO;
        }
      }
      viruses[position] = color;
      virusesRemaining--;
      break;
    }
  }
  return {seed, viruses};
}

function getMaxRow(level: number): number {
  return 9 + Math.max(0, Math.floor((level - 13) / 2));
}

function rotateBytes(seed: Seed): Seed {
  let carry0 = 0;
  let carry1 = 0;
  if (((seed[0] & 2) ^ (seed[1] & 2)) !== 0) {
    carry0 = 1;
    carry1 = 1;
  }
  for (let x = 0; x < 2; x++) {
    carry0 = seed[x] & 1;
    seed[x] = (carry1 << 7) | (seed[x] >> 1);
    carry1 = carry0;
  }
  return seed;
}

interface InitialEntities {
  capsules: Capsule[];
  entities: Entities;
  nextSeed: Seed;
}

function getInitialEntities(seed: Seed, level: number = 20): InitialEntities {
  const entities: Entities = [];
  const {seed: afterCapsuleSeed, capsules} = generateCapsules(seed);
  const {seed: afterVirusesSeed, viruses} = generateViruses(
    afterCapsuleSeed,
    level
  );
  viruses.forEach((color, position) => {
    entities[position] = {
      entityType: EntityType.VIRUS,
      color,
    };
  });
  return {
    capsules,
    entities,
    nextSeed: afterVirusesSeed,
  };
}

function generateCapsules(seed: Seed): {seed: Seed; capsules: Capsule[]} {
  let currentSeed = seed.slice();
  const capsules = [];
  let lastCapsule = 0;
  for (
    let capsulesRemaining = NUM_CAPSULES;
    capsulesRemaining > 0;
    capsulesRemaining--
  ) {
    currentSeed = rotateBytes(currentSeed);
    const capsule = ((currentSeed[0] % 16) + lastCapsule) % 9;
    lastCapsule = capsule;
    capsules[capsulesRemaining - 1] = {
      colorLeft: Math.floor(capsule / 3),
      colorRight: capsule % 3,
    };
  }
  return {seed: currentSeed, capsules};
}

export function isPlayerActive(phase: Phase): boolean {
  return phase.phaseType === PhaseType.PLAYER_ACTIVE;
}

function setCapsuleDetached(state: PlayerState, capsuleIndex: number) {
  const capsuleHalf = state.entities[capsuleIndex];
  if (capsuleHalf !== undefined) {
    capsuleHalf.entityType = EntityType.CAPSULE_DETACHED;
  }
}

function isLocked(entities: Entities): boolean {
  let canDrop = false;
  for (let index = NUM_POSITIONS - 1; index >= 0; index--) {
    const entity = entities[index];
    const downPosition = index + WIDTH;
    if (!entity || downPosition >= NUM_POSITIONS) {
      continue;
    }
    switch (entity.entityType) {
      case EntityType.CAPSULE_DETACHED:
      case EntityType.CAPSULE_DOWN:
        if (entities[downPosition] === undefined) {
          canDrop = true;
        }
        break;
      case EntityType.CAPSULE_LEFT:
        if (
          entities[downPosition] === undefined &&
          entities[downPosition + 1] === undefined
        ) {
          canDrop = true;
        }
        break;
    }
  }
  return !canDrop;
}

function copyEntities(entities: Entities): Entities {
  const copied: Entities = [];
  for (let i = 0; i < NUM_POSITIONS; i++) {
    if (!entities[i]) {
      continue;
    }
    copied[i] = Object.assign({}, entities[i]);
  }
  return copied;
}

function resetState(
  seed: Seed,
  level: number,
  speed: Speed,
  previousFrameTotal: number = 0
): State {
  const {capsules, entities, nextSeed} = getInitialEntities(seed, level);
  return {
    frame: 0,
    previousFrameTotal,
    capsules,
    level,
    seed,
    nextSeed,
    isPaused: false,
    player1State: {
      inputs: new Set<Input>(),
      position: {x: INITIAL_POSITION_X, y: INITIAL_POSITION_Y},
      rotation: 0,
      entities: copyEntities(entities),
      das: 0,
      fallCounter: 0,
      activeCapsuleIndex: 1,
      numCapsules: 0,
      phase: {phaseType: PhaseType.ENTRY_DELAY, data: CONFIG_ENTRY_DELAY},
      pendingAttackColors: [],
      pendingGarbageColors: [],
      pendingGarbageFrame: -1,
      playerNumber: 1,
      speed,
      speedCounter: 0,
    },
    player2State: {
      inputs: new Set<Input>(),
      position: {x: INITIAL_POSITION_X, y: INITIAL_POSITION_Y},
      rotation: 0,
      entities: copyEntities(entities),
      das: 0,
      fallCounter: 0,
      activeCapsuleIndex: 1,
      numCapsules: 0,
      phase: {phaseType: PhaseType.ENTRY_DELAY, data: CONFIG_ENTRY_DELAY},
      pendingAttackColors: [],
      pendingGarbageColors: [],
      pendingGarbageFrame: -1,
      playerNumber: 2,
      speed,
      speedCounter: 0,
    },
  };
}
