import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  Screen,
  navigate,
  setPressedRepeat,
  selectPressedInputs,
} from '../redux/reducers/app';
import {setNumPlayers} from '../redux/reducers/game_config';
import {
  decrementSelectedIndex,
  incrementSelectedIndex,
  selectSelectedIndex,
} from '../redux/reducers/title';
import {Input} from '../util/input';
import styles from './Title.module.scss';

interface Option {
  label: string;
  screen: Screen;
  numPlayers?: number;
}

const OPTIONS: Option[] = [
  {label: '1p', screen: Screen.GAME_CONFIG, numPlayers: 1},
  {label: '2p', screen: Screen.LOBBY_CONFIG, numPlayers: 2},
  {label: 'config', screen: Screen.CONFIG},
];

export function Title() {
  const dispatch = useAppDispatch();

  const pressedInputs = useAppSelector(selectPressedInputs);
  const selectedIndex = useAppSelector(selectSelectedIndex);

  useGranularEffect(
    () => {
      if (pressedInputs[Input.START] === false) {
        dispatch(setPressedRepeat(Input.START));
        const option = OPTIONS[selectedIndex];
        if (option.numPlayers !== undefined) {
          dispatch(setNumPlayers(option.numPlayers));
        }
        dispatch(navigate(option.screen));
      } else if (pressedInputs[Input.LEFT] === false) {
        dispatch(decrementSelectedIndex());
      } else if (pressedInputs[Input.RIGHT] === false) {
        dispatch(incrementSelectedIndex(OPTIONS.length));
      }
    },
    [pressedInputs],
    [dispatch, selectedIndex]
  );

  return (
    <div className={styles.El}>
      <div className={styles.Title}>vitamin</div>
      <div className={styles.Options}>
        {OPTIONS.map((option, index) => (
          <button
            key={option.label}
            className={
              styles.Option +
              (index === selectedIndex ? ` ${styles.isSelected}` : '')
            }
            tabIndex={-1}
            onClick={(event) => {
              event.preventDefault();
              dispatch(navigate(option.screen));
            }}>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}
