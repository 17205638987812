import {useState} from 'react';
import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  Screen,
  navigate,
  setPressedRepeat,
  selectPressedInputs,
} from '../redux/reducers/app';
import {
  decrementLevel,
  incrementLevel,
  decrementSpeed,
  incrementSpeed,
  selectHardDrop,
  selectNumPlayers,
  selectLevel,
  selectSeed,
  selectSpeed,
  toggleHardDrop,
  updateSeed,
} from '../redux/reducers/game_config';
import {NavigateBack} from './NavigateBack';
import styles from './GameConfig.module.scss';
import {speedToString} from '../util/speed';
import {Input} from '../util/input';
import {booleanToString} from '../util/boolean';

interface Option {
  label: string;
  value: JSX.Element | string;
  decrement: any;
  increment: any;
}

export function GameConfig() {
  const dispatch = useAppDispatch();

  const pressedInputs = useAppSelector(selectPressedInputs);

  const numPlayers = useAppSelector(selectNumPlayers);
  const level = useAppSelector(selectLevel);
  const speed = useAppSelector(selectSpeed);
  const seed = useAppSelector(selectSeed);
  const hardDrop = useAppSelector(selectHardDrop);

  const [seedDigit, setSeedDigit] = useState(0);

  const options: Option[] = [
    {
      label: 'level',
      value: `${level}`,
      decrement: decrementLevel(),
      increment: incrementLevel(),
    },
    {
      label: 'speed',
      value: speedToString(speed),
      decrement: decrementSpeed(),
      increment: incrementSpeed(),
    },
    {
      label: 'seed',
      value: (
        <div className={styles.Seed}>
          <div
            className={styles.SeedDigit}
            style={{
              left: `${seedDigit}ch`,
            }}></div>
          {seed}
        </div>
      ),
      decrement: updateSeed({seedDigit, amount: -1}),
      increment: updateSeed({seedDigit, amount: 1}),
    },
    {
      label: 'hard drop',
      value: booleanToString(hardDrop),
      decrement: toggleHardDrop(),
      increment: toggleHardDrop(),
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  useGranularEffect(
    () => {
      const option = options[selectedIndex];
      if (pressedInputs[Input.START] === false) {
        dispatch(setPressedRepeat(Input.START));
        dispatch(navigate(Screen.GAME));
      } else if (pressedInputs[Input.UP] === false) {
        setSelectedIndex((currentSelectedIndex) =>
          Math.max(0, currentSelectedIndex - 1)
        );
      } else if (pressedInputs[Input.DOWN] === false) {
        setSelectedIndex((currentSelectedIndex) =>
          Math.min(options.length - 1, currentSelectedIndex + 1)
        );
      } else if (pressedInputs[Input.RIGHT] === false) {
        dispatch(option.increment);
      } else if (pressedInputs[Input.LEFT] === false) {
        dispatch(option.decrement);
      } else if (
        pressedInputs[Input.SELECT] === false &&
        option.label === 'seed'
      ) {
        setSeedDigit((currentSeedDigit) => {
          return (currentSeedDigit + 1) % 4;
        });
      }
    },
    [pressedInputs],
    [dispatch, selectedIndex, options]
  );

  return (
    <div className={styles.El}>
      <NavigateBack />
      <div className={styles.Title}>{numPlayers}p game</div>
      <div className={styles.Options}>
        {options.map((option, index) => (
          <div
            key={option.label}
            className={
              styles.Option +
              (index === selectedIndex ? ` ${styles.isSelected}` : '')
            }>
            <div className={styles.Label}>{option.label}</div>
            <div className={styles.Value}>{option.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
