import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {LOCAL_STORAGE_PREFIX} from '../../util/storage';
import type {RootState} from '../util/store';

const HANDLE_KEY = 'handle';

interface GameConfigState {
  handle: string | null;
}

const initialState: GameConfigState = {
  handle: getHandleLocalStorage(),
};

const slice = createSlice({
  name: 'multiplayer',
  initialState,
  reducers: {
    setHandle: (state, action: PayloadAction<string>) => {
      state.handle = action.payload;
      updateHandleLocalStorage(state.handle);
    },
  },
});

export const {setHandle} = slice.actions;

export const selectHandle = (state: RootState) => state.multiplayer.handle;

export const multiplayerReducer = slice.reducer;

function updateHandleLocalStorage(handle: string): void {
  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${HANDLE_KEY}`, handle);
}

function getHandleLocalStorage(): string | null {
  return localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${HANDLE_KEY}`);
}
