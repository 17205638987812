import {useRef, useState} from 'react';
import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {NavigateBack} from './NavigateBack';
import styles from './LobbyConfig.module.scss';
import {selectHandle, setHandle} from '../redux/reducers/multiplayer';
import {
  navigate,
  Screen,
  selectPressedInputs,
  setInputListeningEnabled,
} from '../redux/reducers/app';
import {Input} from '../util/input';

interface Option {
  label: string;
  value: string;
  input: JSX.Element;
  onRender: () => void;
  onSelect: () => void;
}

export function LobbyConfig() {
  const dispatch = useAppDispatch();

  const handle = useAppSelector(selectHandle);
  const pressedInputs = useAppSelector(selectPressedInputs);

  const handleInputRef = useRef<HTMLInputElement>(null);

  const options: Option[] = [
    {
      label: 'handle',
      value: handle ?? '<not set>',
      input: (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            selectAndCloseDialog();
          }}>
          handle{' '}
          <input
            type="text"
            className={styles.Input}
            ref={handleInputRef}
            defaultValue={handle ?? ''}></input>
        </form>
      ),
      onRender: (): void => {
        handleInputRef.current?.focus();
      },
      onSelect: (): void => {
        if (handleInputRef.current) {
          const value = handleInputRef.current.value;
          dispatch(setHandle(value));
        }
      },
    },
  ];

  const enterLobbyButtonIndex = options.length;

  const [selectedIndex, setSelectedIndex] = useState(enterLobbyButtonIndex);
  const [updatingIndex, setUpdatingIndex] = useState<number | null>(null);

  function openDialog(index: number): void {
    setUpdatingIndex(index);
    dispatch(setInputListeningEnabled(false));
  }

  function closeDialog(): void {
    setUpdatingIndex(null);
    dispatch(setInputListeningEnabled(true));
  }

  function selectAndCloseDialog(): void {
    if (updatingIndex !== null) {
      options[updatingIndex].onSelect();
    }
    closeDialog();
  }

  useGranularEffect(
    () => {
      if (updatingIndex !== null) {
        options[updatingIndex].onRender();
        return;
      }
      if (pressedInputs[Input.START] === false) {
        if (selectedIndex === enterLobbyButtonIndex) {
          dispatch(navigate(Screen.LOBBY));
        } else {
          openDialog(selectedIndex);
        }
      } else if (pressedInputs[Input.UP] === false) {
        setSelectedIndex(Math.max(0, selectedIndex - 1));
      } else if (pressedInputs[Input.DOWN] === false) {
        setSelectedIndex(Math.min(options.length, selectedIndex + 1));
      }
    },
    [pressedInputs, updatingIndex],
    [selectedIndex, options, openDialog]
  );

  return (
    <div className={styles.El}>
      <NavigateBack />
      <div className={styles.Title}>lobby config</div>
      <div className={styles.Options}>
        {options.map((option, index) => (
          <div
            key={option.label}
            className={
              styles.Option +
              (index === selectedIndex ? ` ${styles.isSelected}` : '')
            }
            onClick={(event) => {
              event.preventDefault();
              openDialog(selectedIndex);
            }}>
            <div className={styles.Label}>{option.label}</div>
            <div className={styles.Value}>{option.value}</div>
          </div>
        ))}
      </div>
      <div
        className={
          styles.Button +
          (selectedIndex === enterLobbyButtonIndex
            ? ` ${styles.isSelected}`
            : '')
        }>
        enter lobby
      </div>
      {updatingIndex !== null && (
        <div className={styles.UpdatingDialogContainer}>
          <div className={styles.UpdatingDialog}>
            {options[updatingIndex].input}
            <button
              className={styles.DialogButton}
              onClick={(event) => {
                event.preventDefault();
                selectAndCloseDialog();
              }}>
              set
            </button>
            <button
              className={styles.DialogButton}
              onClick={(event) => {
                event.preventDefault();
                closeDialog();
              }}>
              cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
