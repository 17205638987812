export enum Input {
  LEFT,
  RIGHT,
  DOWN,
  UP,
  A,
  B,
  START,
  SELECT,
}

export type InputMap = {[key: number]: boolean};

export function getInputMap(
  inputs: Set<Input>,
  previousInputs: Set<Input>
): InputMap {
  const inputMap: InputMap = {};
  for (const input of inputs.values()) {
    inputMap[input] = previousInputs.has(input);
  }
  return inputMap;
}

export function getInputName(input: Input): string {
  switch (input) {
    case Input.LEFT:
      return 'left';
    case Input.RIGHT:
      return 'right';
    case Input.DOWN:
      return 'down';
    case Input.UP:
      return 'up';
    case Input.A:
      return 'a';
    case Input.B:
      return 'b';
    case Input.START:
      return 'start';
    case Input.SELECT:
      return 'select';
  }
}
