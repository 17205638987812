import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Input, InputMap} from '../../util/input';
import type {RootState} from '../util/store';

interface AppState {
  screenStack: Screen[];
  pressedInputs: InputMap;
  gamepadIndex: number | null;
  inputListeningEnabled: boolean;
}

export enum Screen {
  TITLE,
  CONFIG,
  GAME_CONFIG,
  LOBBY_CONFIG,
  LOBBY,
  GAME,
}

const initialState: AppState = {
  screenStack: [Screen.TITLE],
  pressedInputs: {},
  gamepadIndex: null,
  inputListeningEnabled: true,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    navigate: (state, action: PayloadAction<Screen>) => {
      state.screenStack.push(action.payload);
    },
    navigateBack: (state) => {
      if (state.screenStack.length > 1) {
        state.screenStack.pop();
      }
    },
    setPressed: (state, action: PayloadAction<Input>) => {
      state.pressedInputs[action.payload] =
        state.pressedInputs[action.payload] !== undefined;
    },
    setPressedRepeat: (state, action: PayloadAction<Input>) => {
      state.pressedInputs[action.payload] = true;
    },
    setNotPressed: (state, action: PayloadAction<Input>) => {
      delete state.pressedInputs[action.payload];
    },
    clearPressed: (state) => {
      state.pressedInputs = {};
    },
    setGamepadIndex: (state, action: PayloadAction<number | null>) => {
      state.gamepadIndex = action.payload;
    },
    setInputListeningEnabled: (state, action: PayloadAction<boolean>) => {
      state.inputListeningEnabled = action.payload;
    },
  },
});

export const {
  navigate,
  navigateBack,
  setPressed,
  setPressedRepeat,
  setNotPressed,
  clearPressed,
  setGamepadIndex,
  setInputListeningEnabled,
} = slice.actions;

export const selectScreen = (state: RootState) =>
  state.app.screenStack[state.app.screenStack.length - 1];
export const selectPressedInputs = (state: RootState) =>
  state.app.pressedInputs;
export const selectGamepadIndex = (state: RootState) => state.app.gamepadIndex;
export const selectInputListeningEnabled = (state: RootState) =>
  state.app.inputListeningEnabled;

export const appReducer = slice.reducer;
