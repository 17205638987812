import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Speed} from '../../util/speed';

import type {RootState} from '../util/store';

interface GameConfigState {
  numPlayers: number;
  level: number;
  speed: Speed;
  seed: string;
  hardDrop: boolean;
  remotePeerId: string;
}

const initialState: GameConfigState = {
  numPlayers: 1,
  level: 0,
  speed: Speed.MED,
  seed: '0000',
  hardDrop: false,
  remotePeerId: '',
};

export interface SeedUpdate {
  seedDigit: number;
  amount: number;
}

const MAX_LEVEL = 20;

const slice = createSlice({
  name: 'gameConfig',
  initialState,
  reducers: {
    setNumPlayers: (state, action: PayloadAction<number>) => {
      state.numPlayers = action.payload;
    },
    decrementLevel: (state) => {
      state.level = Math.max(0, state.level - 1);
    },
    incrementLevel: (state) => {
      state.level = Math.min(MAX_LEVEL, state.level + 1);
    },
    decrementSpeed: (state) => {
      state.speed = Math.max(Speed.LOW, state.speed - 1);
    },
    incrementSpeed: (state) => {
      state.speed = Math.min(Speed.HI, state.speed + 1);
    },
    updateSeed: (state, action: PayloadAction<SeedUpdate>) => {
      const currentSeedDigitValue = state.seed.substring(
        action.payload.seedDigit,
        action.payload.seedDigit + 1
      );
      const newSeedDigitValue = (
        (parseInt(currentSeedDigitValue, 16) + 16 + action.payload.amount) %
        16
      ).toString(16);
      const beforeSeedDigitValue = state.seed.substring(
        0,
        action.payload.seedDigit
      );
      const afterSeedDigitValue = state.seed.substring(
        action.payload.seedDigit + 1,
        4
      );
      state.seed = `${beforeSeedDigitValue}${newSeedDigitValue}${afterSeedDigitValue}`;
    },
    toggleHardDrop: (state) => {
      state.hardDrop = !state.hardDrop;
    },
    setLevel: (state, action: PayloadAction<number>) => {
      state.level = action.payload;
    },
    setSpeed: (state, action: PayloadAction<Speed>) => {
      state.speed = action.payload;
    },
    setSeed: (state, action: PayloadAction<string>) => {
      state.seed = action.payload;
    },
    setHardDrop: (state, action: PayloadAction<boolean>) => {
      state.hardDrop = action.payload;
    },
    setRemotePeerId: (state, action: PayloadAction<string>) => {
      state.remotePeerId = action.payload;
    },
  },
});

export const {
  setNumPlayers,
  decrementLevel,
  incrementLevel,
  decrementSpeed,
  incrementSpeed,
  updateSeed,
  toggleHardDrop,
  setLevel,
  setSpeed,
  setSeed,
  setHardDrop,
  setRemotePeerId,
} = slice.actions;

export const selectNumPlayers = (state: RootState) =>
  state.gameConfig.numPlayers;
export const selectLevel = (state: RootState) => state.gameConfig.level;
export const selectSpeed = (state: RootState) => state.gameConfig.speed;
export const selectSeed = (state: RootState) => state.gameConfig.seed;
export const selectHardDrop = (state: RootState) => state.gameConfig.hardDrop;
export const selectRemotePeerId = (state: RootState) =>
  state.gameConfig.remotePeerId;

export const gameConfigReducer = slice.reducer;
