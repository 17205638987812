export enum Color {
  COLOR_ONE,
  COLOR_TWO,
  COLOR_THREE,
  COLOR_INVALID,
}

export const VIRUS_COLOR_TABLE: Color[] = [
  Color.COLOR_ONE,
  Color.COLOR_TWO,
  Color.COLOR_THREE,
  Color.COLOR_THREE,
  Color.COLOR_TWO,
  Color.COLOR_ONE,
  Color.COLOR_ONE,
  Color.COLOR_TWO,
  Color.COLOR_THREE,
  Color.COLOR_THREE,
  Color.COLOR_TWO,
  Color.COLOR_ONE,
  Color.COLOR_ONE,
  Color.COLOR_TWO,
  Color.COLOR_THREE,
  Color.COLOR_TWO,
];

type MaskMap = {[key in Color]: number};

export const VIRUS_COLOR_BIT_MASKS: MaskMap = {
  [Color.COLOR_ONE]: 1,
  [Color.COLOR_TWO]: 2,
  [Color.COLOR_THREE]: 4,
  [Color.COLOR_INVALID]: 0,
};
