import {Color} from './color';
import {Entities, Entity, EntityType} from './entity';
import {Input, InputMap} from './input';
import {Position} from './position';

/** The width of the bottle. */
export const WIDTH = 8;
/** The height of the bottle. */
export const HEIGHT = 16;

const DAS = 16;
const ARR = 6;

/**
 * Returns if the given {Position} of the capsule and rotation are currently occupied by
 * another entity already, indicating a collision has occurred.
 */
export function hasCollision(
  entities: Entities,
  position: Position,
  rotation: number
): boolean {
  return (
    isOutOfBounds(position, rotation) ||
    hasEntityCollision(entities, position, rotation)
  );
}

function hasEntityCollision(
  entities: Entities,
  position: Position,
  rotation: number
): boolean {
  return (
    !isOutOfBounds(position, rotation) &&
    (entityExists(entities, position) ||
      entityExists(entities, position, rotation))
  );
}

function isOutOfBounds(position: Position, rotation: number): boolean {
  return (
    isOutOfBoundsLeft(position) ||
    isOutOfBoundsRight(position, rotation) ||
    isOutOfBoundsDown(position)
  );
}

function isOutOfBoundsLeft(position: Position): boolean {
  return position.x < 0;
}

function isOutOfBoundsRight(position: Position, rotation: number): boolean {
  return position.x > WIDTH - (rotation % 2 === 0 ? 2 : 1);
}

function isOutOfBoundsDown(position: Position): boolean {
  return position.y >= HEIGHT;
}

function entityExists(
  entities: Entities,
  position: Position,
  rotation?: number
): boolean {
  return entities[getEntityIndex(position, rotation)] !== undefined;
}

/**
 * Gets the index into an {Entities} array from a {Position} object.
 * If {rotation} is not provided, the position of the left/bottom half of the
 * capsule is returned.
 * If {rotation} is provided, the position fo the right/top half of the capsule
 * is returned.
 */
export function getEntityIndex(position: Position, rotation?: number): number {
  let transformed = position.y * WIDTH + position.x;
  if (rotation !== undefined) {
    transformed += rotation % 2 === 0 ? 1 : -1 * WIDTH;
  }
  return transformed;
}

export interface PositionState {
  position: Position;
  das: number;
  fallCounter: number;
  rotation: number;
  isLocked: boolean;
}

/**
 * Updates the given {PositionState} from the previous one taking into account
 * the pressed inputs, the entities on the board, and the current number of fall
 * frames (calculated by the speed and number of capsules that have elapsed).
 */
export function updatePositionState(
  pressedInputs: InputMap,
  entities: Entities,
  positionState: PositionState,
  fallFrames: number,
  hardDrop: boolean = false
): PositionState {
  if (positionState.isLocked) {
    return positionState;
  }

  let position = Object.assign({}, positionState.position);
  // This can't be done in the destructuring below because of type narrowing
  let isLocked: boolean = positionState.isLocked;
  let {das, fallCounter, rotation} = positionState;

  const downRepeat = pressedInputs[Input.DOWN];
  const leftRepeat = pressedInputs[Input.LEFT];
  const rightRepeat = pressedInputs[Input.RIGHT];
  const upRepeat = pressedInputs[Input.UP];

  if (hardDrop && upRepeat === false) {
    while (!hasCollision(entities, position, rotation)) {
      position.y++;
    }
    fallCounter = 0;
    position.y--;
    isLocked = true;
  } else {
    let actualFallFrames = fallFrames;
    if (
      downRepeat !== undefined &&
      leftRepeat === undefined &&
      rightRepeat === undefined &&
      upRepeat === undefined
    ) {
      if (!downRepeat) {
        actualFallFrames = 0;
      } else {
        actualFallFrames = 2;
      }
    }
    fallCounter++;
    if (fallCounter >= actualFallFrames) {
      position.y++;
      fallCounter = 0;
      if (hasCollision(entities, position, rotation)) {
        position.y--;
        isLocked = true;
      }
    }
  }

  if (!isLocked) {
    if (leftRepeat !== undefined) {
      if (!leftRepeat) {
        position.x--;
        das = 0;
      } else {
        das++;
        if (das >= DAS) {
          das = DAS - ARR;
          position.x--;
        }
      }
    } else if (rightRepeat !== undefined) {
      if (!rightRepeat) {
        position.x++;
        das = 0;
      } else {
        das++;
        if (das >= DAS) {
          das = DAS - ARR;
          position.x++;
        }
      }
    }
    if (
      position.x !== positionState.position.x &&
      hasCollision(entities, position, rotation)
    ) {
      // Entity charge
      if (hasEntityCollision(entities, position, rotation)) {
        das = DAS;
      }
      // Reset X if there was a horizontal collision
      position.x = positionState.position.x;
    }

    const aRepeat = pressedInputs[Input.A];
    const bRepeat = pressedInputs[Input.B];

    let rotationChanged = false;
    if (aRepeat === false) {
      rotation = (rotation + 3) % 4;
      rotationChanged = true;
    }
    if (bRepeat === false) {
      rotation = (rotation + 1) % 4;
      rotationChanged = true;
    }
    if (rotationChanged) {
      let shouldContinue = true;
      if (rotation % 2 === 0) {
        if (hasCollision(entities, position, rotation)) {
          position.x--; // kick
        } else {
          if (leftRepeat !== undefined) {
            position.x--; // left-rotate anomaly
            if (hasCollision(entities, position, rotation)) {
              position.x++;
            }
          }
          shouldContinue = false;
        }
      }
      if (shouldContinue && hasCollision(entities, position, rotation)) {
        rotation = positionState.rotation;
        position.x = positionState.position.x;
      }
    }
  }

  return {
    position,
    das,
    fallCounter,
    rotation,
    isLocked,
  };
}

export function getClearedIndices(
  entities: Array<Entity | undefined>,
  clearNumber: number
): number[] {
  const clearedIndices: number[] = [];
  let lastColor: Color | undefined;
  let run = 0;
  for (let index = 0; index < entities.length; index++) {
    const color = entities[index]?.color;
    const colorMatches = color === lastColor;
    if (color !== undefined && colorMatches) {
      run++;
    }
    if (
      run >= clearNumber &&
      (!colorMatches || index === entities.length - 1)
    ) {
      const offset = colorMatches ? 0 : 1;
      const startIndex = index + 1 - run - offset;
      const endIndex = startIndex + run - 1;
      for (let i = startIndex; i <= endIndex; i++) {
        clearedIndices.push(i);
      }
    }
    if (!colorMatches) {
      run = 1;
    }
    lastColor = color;
  }
  return clearedIndices;
}

export function isWin(entities: Entities): boolean {
  return !entities.find((entity) => entity?.entityType === EntityType.VIRUS);
}
