export interface GamepadMapping {
  pressType: GamepadPressType;
  index: number;
  pressDirection: GamepadPressDirection;
}

export enum GamepadPressType {
  AXIS,
  BUTTON,
}

export enum GamepadPressDirection {
  NEGATIVE,
  POSITIVE,
}

// e.g. gamepad-axis-1-negative
export function serializeGamepadMapping(
  gamepadMapping: GamepadMapping
): string {
  let pressType = '';
  switch (gamepadMapping.pressType) {
    case GamepadPressType.AXIS:
      pressType = 'axis';
      break;
    case GamepadPressType.BUTTON:
      pressType = 'button';
      break;
  }
  const index = gamepadMapping.index;
  let pressDirection = '';
  switch (gamepadMapping.pressDirection) {
    case GamepadPressDirection.NEGATIVE:
      pressDirection = 'negative';
      break;
    case GamepadPressDirection.POSITIVE:
      pressDirection = 'positive';
      break;
  }
  return `gamepad-${pressType}-${index}-${pressDirection}`;
}

// e.g. gamepad-axis-1-negative
export function deserializeGamepadMapping(
  mapping: string
): GamepadMapping | null {
  if (mapping.indexOf('-') === -1) {
    return null;
  }
  const parts = mapping.split('-');
  if (parts.length !== 4) {
    return null;
  }
  let pressType = GamepadPressType.AXIS;
  switch (parts[1]) {
    case 'button':
      pressType = GamepadPressType.BUTTON;
      break;
  }
  const index = parseInt(parts[2], 10);
  let pressDirection = GamepadPressDirection.NEGATIVE;
  switch (parts[3]) {
    case 'positive':
      pressDirection = GamepadPressDirection.POSITIVE;
      break;
  }
  return {
    pressType,
    index,
    pressDirection,
  };
}
