import {useGranularEffect} from 'granular-hooks';

import {useAppDispatch, useAppSelector} from '../redux/util/hooks';
import {
  navigateBack,
  setPressedRepeat,
  selectPressedInputs,
} from '../redux/reducers/app';
import {selectInputMap} from '../redux/reducers/config';
import {Input} from '../util/input';
import styles from './NavigateBack.module.scss';

interface Props {
  enabled?: boolean;
}

export function NavigateBack({enabled = true}: Props) {
  const dispatch = useAppDispatch();

  const pressedInputs = useAppSelector(selectPressedInputs);
  const inputMap = useAppSelector(selectInputMap);

  useGranularEffect(
    () => {
      if (!enabled) {
        return;
      }
      if (pressedInputs[Input.B] === false) {
        Object.keys(inputMap)
          .map((inputString) => parseInt(inputString, 10))
          .filter((input) => inputMap[Input.B] === inputMap[input])
          .forEach((input) => {
            dispatch(setPressedRepeat(input));
          });
        dispatch(navigateBack());
      }
    },
    [pressedInputs, enabled],
    [dispatch, inputMap]
  );

  return <div className={styles.El}>press &lt;b&gt; to go back</div>;
}
