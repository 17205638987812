export type Seed = number[];

export function isValidSeed(seed: string): boolean {
  const numericValue = parseInt(seed, 16);
  return numericValue > 1 && new RegExp('^[0-9a-fA-F]{4}$').test(seed);
}

export function stringToSeed(seed: string): Seed {
  if (!isValidSeed(seed)) {
    throw new Error('Attempted to transform an invalid string into a seed');
  }
  return [
    parseInt(seed.substring(0, 2), 16),
    parseInt(seed.substring(2, 4), 16),
  ];
}

export function getRandomSeed(): Seed {
  const seed = [getRandomUnsignedByte()];
  do {
    seed[1] = getRandomUnsignedByte();
  } while (seed[0] === 0 && seed[1] <= 1);
  return seed;
}

function getRandomUnsignedByte(): number {
  return Math.floor(Math.random() * 255);
}
