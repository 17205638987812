export enum Speed {
  LOW,
  MED,
  HI,
}

export const SPEED_BASES: Map<Speed, number> = new Map([
  [Speed.LOW, 15],
  [Speed.MED, 25],
  [Speed.HI, 31],
]);

export function speedToString(speed: Speed): string {
  switch (speed) {
    case Speed.LOW:
      return 'LOW';
    case Speed.MED:
      return 'MED';
    case Speed.HI:
      return 'HI';
  }
}

export function getFallFrames(speed: Speed, speedCounter: number): number {
  const index = Math.min(49, speedCounter) + (SPEED_BASES.get(speed) || 0);
  if (index >= 80) {
    return 1;
  }
  if (index >= 70) {
    return 2;
  }
  if (index >= 55) {
    return 16 - Math.floor(index / 5);
  }
  if (index >= 45) {
    return 6;
  }
  if (index >= 35) {
    return 10 - Math.floor((index - 35) / 2);
  }
  if (index >= 26) {
    return 45 - index;
  }
  return (35 - index) * 2;
}
